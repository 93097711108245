export const costTypes = {
  salaryIncrease: 'salaryIncrease',
  longevityPay: 'longevityPay',
  pension: 'pension',
  medicalInsurance: 'medicalInsurance',
  healthcare: 'healthcare',
  otherInsurance: 'otherInsurance',
  otherPay: 'otherPay',
  otherBenefits: 'otherBenefits',
  expenses: 'expenses',
};

export const costTypeNames = () => {
  return Object.keys(costTypes);
};

export const planSelections = {
  employeeOnly: 'Employee-Only',
  employeePlus1: 'Employee+1',
  employeePlusChild: 'Employee+Child',
  family: 'Family',
  waived: 'Waived',
};

export const planSelectionKeys = () => {
  return Object.keys(planSelections);
};

export const planSelectionNames = () => {
  return Object.keys(planSelections).map(x => planSelections[x]);
};

export const fileTypes = {
  tableData: 'tableData',
  tableDataFile: 'tableDataFile',
}

// action types
export const DISMISS_ERROR = 'DISMISS_ERROR';
export const SET_HEADER_MODE = 'SET_HEADER_MODE';

export const roles = {
  SysAdmin: 'SysAdmin',
  Operator: 'Operator',
};
export const tenantAdminRoles = [
  roles.SysAdmin, roles.Operator
];

export const COMP_BENCHMARKING_TENANT_ID = 'I0znTYkDEW7jbcoPyV4Ew';